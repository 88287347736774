
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import { BankRatesObject } from '@/store/modules/BankRatesModule';

  export default defineComponent({
    name: 'admin-bank-rates-overview',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bankRatesOverview'), [
          translate('banks'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_BANK_RATE,
        route.params.id
      );

      const bankRate = ref<BankRatesObject>(data.data);

      await store.dispatch(Actions.GET_BANK_RATES_ENUM_ALL);
      const banks = computed(() => store.getters.bankRatesList);
      const selectedRate = ref<number>(banks.value[0].repaymentPeriod);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        bankRates: computed(() => store.getters.bankRatesList),
        bankRate,
        submitButton,
        selectedRate,
        translate,
        goBack,
        can,
      };
    },
  });
