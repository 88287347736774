import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "collapse show" }
const _hoisted_6 = { class: "card-body border-top p-9" }
const _hoisted_7 = { class: "row mb-6" }
const _hoisted_8 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_9 = { class: "col-lg-8 fv-row" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "row mb-6" }
const _hoisted_13 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_14 = { class: "col-lg-8 fv-row" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "row mb-6" }
const _hoisted_18 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_19 = { class: "required" }
const _hoisted_20 = { class: "col-lg-8 fv-row" }
const _hoisted_21 = { class: "card-footer d-flex justify-content-end py-6 px-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_ctx.can('read', 'admin-banks-rates'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('bankRatesOverview')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Form, { class: "form" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.translate('boubyanCustomerPercentage')), 1),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_Field, {
                      disabled: "",
                      type: "number",
                      name: "percentage",
                      autocomplete: "off",
                      class: "form-control form-control-lg form-control-solid",
                      placeholder: _ctx.translate('boubyanCustomerPercentage'),
                      modelValue: _ctx.bankRate.percentage,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bankRate.percentage) = $event))
                    }, null, 8, ["placeholder", "modelValue"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ErrorMessage, { name: "boubyanCustomerPercentage" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.translate('nonBoubyanCustomerPercentage')), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_Field, {
                      disabled: "",
                      type: "number",
                      name: "notClientPercentage",
                      autocomplete: "off",
                      class: "form-control form-control-lg form-control-solid",
                      placeholder: _ctx.translate('nonBoubyanCustomerPercentage'),
                      modelValue: _ctx.bankRate.notClientPercentage,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bankRate.notClientPercentage) = $event))
                    }, null, 8, ["placeholder", "modelValue"]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_ErrorMessage, { name: "nonBoubyanCustomerPercentage" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("label", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.translate('repaymentPeriod')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_Field, {
                      disabled: "",
                      type: "text",
                      name: "repaymentPeriod",
                      class: "form-select form-select-solid form-select-lg text-capitalize",
                      modelValue: _ctx.bankRate.repaymentPeriod,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bankRate.repaymentPeriod) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("button", {
                  type: "reset",
                  class: "btn btn-white btn-active-light-primary me-2",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                }, _toDisplayString(_ctx.translate('discard')), 1)
              ])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}